<template>
	<v-card
		:style="{ maxWidth: '320px' }"
		outlined
		class="tw-relative tw-group hover:tw-shadow-2xl tw-transition-all tw-rounded-md tw-transform tw-scale-100 hover:tw-scale-105 tw-w-full "
	>
		<div class="tw-text-center tw-py-8">
			<div class="tw-h-16 tw-text-black tw-font-medium tw-text-lg">
				{{ subscription.title }}
			</div>
			<div class="tw-font-bold tw-text-2xl tw-text-black">
				{{ $t('sar') }} {{ subscription.price }}
			</div>
		</div>

		<v-card-text class="tw-h-44 tw-overflow-hidden tw-px-8 tw-text-justify">
			{{ subscription.description }}
		</v-card-text>

		<slot></slot>

		<v-card-actions
			class="tw-px-8 tw-pb-8 tw-mt-4 tw-flex tw-flex-col tw-space-y-4 tw-space-x-0"
		>
			<slot name="actions"></slot>

			<base-button
				v-if="cancelable"
				:loading="loading"
				block
				class="tw-opacity-60 group-hover:tw-opacity-100"
				@click="$emit('unsubscribe', subscription)"
			>
				{{ $t('unsubscribe') }}
			</base-button>

			<base-button v-else-if="subscribed && !cancelable" text block>
				{{ $t('unsubscribed') }}
			</base-button>

			<!-- class="tw-transition-all tw-duration-300 tw-transform tw-scale-0 tw-translate-y-12 tw-opacity-0 group-hover:tw-scale-100 group-hover:tw-translate-y-0 group-hover:tw-opacity-100" -->
			<base-button
				v-else
				:loading="loading"
				:disabled="subscribed"
				:class="subscribed ? '' : 'group-hover:tw-opacity-100'"
				block
				class="tw-opacity-60 "
				@click="$emit('click', subscription)"
			>
				{{ subscribed ? 'Subscribed' : 'Buy' }}
			</base-button>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'SubscriptionCard',
	props: {
		loading: Boolean,
		subscribed: {
			type: Boolean,
			default: false,
		},
		cancelable: {
			type: Boolean,
			default: false,
		},
		subscription: {
			type: Object,
			required: true,
		},
	},
}
</script>
