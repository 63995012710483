<template>
	<div>
		<div v-if="$packages.isLoaded" class="tw-h-full">
			<v-toolbar flat class="tw--ms-1">
				<v-toolbar-title class="tw-text-2xl tw-font-semibold ">
					{{ $t('packages') }}	</v-toolbar-title>
			</v-toolbar>

			<v-container fluid class="tw-h-full">
				<div
					class="tw-flex tw-justify-center tw-items-center tw-h-full tw--ms-8 tw--mt-8 tw-flex-wrap"
				>
					<SubscriptionCard
						v-for="subscription in $packages.data"
						:key="subscription.id"
						:subscription="subscription"
						:loading="isSubscribing === subscription.id"
						class="tw-ms-8 tw-mt-8"
						@click="onPurchasePackage"
					/>
				</div>
			</v-container>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import api from '../api'
import SubscriptionCard from '../components/SubscriptionCard.vue'
import { PAYTAB_PAYMENT_TYPE } from '../consts'

export default {
	name: 'Packages',
	components: { SubscriptionCard },
	data: () => ({
		isSubscribing: false,
	}),
	created() {
		if (this.$currentUser.isSubscribed) {
			return this.$router.push('/subscription')
		}
		this.getPackages()
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('packages', ['$packages']),
	},
	methods: {
		...mapActions('settings', ['setSettingsState']),
		...mapActions('packages', [
			'subscribe',
			'getPackages',
			'unsubscribe',
			'setPackageState',
			'getCurrentSubscription',
		]),
		async onClickBuy({ id }) {
			this.isSubscribing = id
			const [err] = await this.subscribe({ subscriptionId: id })
			this.isSubscribing = null
			if (err) {
				return this.$toast.error(
					err.message || `Error while purchasing the package.`
				)
			}
			await this.$store.dispatch('auth/fetchProfile')
			this.$router.push('/subscription')
		},
		async onPurchasePackage(pkg) {
			this.isSubscribing = pkg.id
			let [err, res] = await this.subscribe({ subscriptionId: pkg.id })
			if (err) {
				this.isSubscribing = null
				return this.$toast.error(this.$t('error-subscribing-package'))
			}
			let [createErr, createRes] = await api.post('payment/create', {
				currency: 'SAR',
				orderId: res.buyerSubscription.orderId,
				type: PAYTAB_PAYMENT_TYPE.SUBSCRIPTION,
			})
			this.isSubscribing = null
			if (createErr) {
				return this.$toast.error(
					this.$t('error-initializing-payment')
				)
			} else {
				this.setSettingsState({
					currentPayment: {
						tranRef: createRes.tranRef,
						type: PAYTAB_PAYMENT_TYPE.SUBSCRIPTION,
					},
				})
			}
			location.replace(createRes.url)
		},
	},
}
</script>
